import React from 'react';
import { graphql} from 'gatsby';
import PrivacyPolicy from '../../templates/multilanguage/PrivacyPolicy';

export default ({ data }) => {
  return <PrivacyPolicy data={data} />;
};

export const query = graphql`
 query PrivacyPolicy {
  mdx(frontmatter: {type: {eq: "privacy-policy"}}) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        type
        title
        metaTitle
        metaDescription
        metaImage {
          publicURL
        }
        metaCover {
          publicURL
        }
        metaKeywords
        twitterCard
        ldJson
      }
  }
}
`
