import React from 'react';
import SiteLayout from '../../components/layout/SiteLayout';
import { Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const PrivacyPolicy = ({ data }) => {

  return (
    <SiteLayout title={data?.mdx?.frontmatter?.title} seo={{metaTitle: data?.mdx?.frontmatter?.metaTitle, metaDescription: data?.mdx?.frontmatter?.metaDescription, metaImage: data?.mdx?.frontmatter?.metaImage?.publicURL, metaCover: data?.mdx?.frontmatter?.metaCover?.publicURL, twitterCard: data?.mdx?.frontmatter?.twitterCard, keywords: data?.mdx?.frontmatter?.metaKeywords, slug: data?.mdx?.fields?.slug.replace("/posts", "")}}>
      <div className={"w-full flex flex-col features-bg-image px-5 md:px-10 lg:px-40 xl:px-80 xxl:px-96  "}>
        <div className={"w-full"}>
          <p className={"text-right w-full text-sm text-gray-500 mt-5"}><Link to={"/"}>Home</Link> > Privacy Policy</p>
          </div>
        <h1 className={"text-xl md:text-2xl lg:text-4xl text-center text-primary-default font-light py-20"}>{data?.mdx?.frontmatter?.title}</h1>
      </div>
      <div className={"w-full flex flex-col px-5 md:px-10 lg:px-40 xl:px-80 xxl:px-96   py-10 text-gray-500 text-sm mdx-pp-tos"}>
        <MDXRenderer>{data?.mdx?.body}</MDXRenderer>
      </div>
    </SiteLayout>
  );
};

export default PrivacyPolicy;
